<template>
  <div class="page-update-post">
    <post-update-form
      v-if="postData !== undefined"
      :postData="postData"></post-update-form>
  </div>
</template>

<script>
import PostUpdateForm from "@/components/posts/PostUpdateForm.vue";
import axios from "axios";
export default {
  name: "UpdatePost",
  data() {
    return {
      postData: undefined,
    };
  },
  components: {
    PostUpdateForm,
  },
  methods: {
    checkForPostData() {
      if (this.$store.state.nextViewPost !== undefined) {
        this.postData = this.$store.state.nextViewPost;
        document.title = "updating " + this.postData.title + " | icandoathing";
        if (
          this.postData.author.username !== this.$store.state.userData.username
        ) {
          this.$router.push("/");
        }
      } else {
        this.$store.commit("setLoading", true);
        axios
          .get("api/v1/posts/id/" + this.$route.params.id + "/")
          .then((res) => {
            this.postData = res.data;
            document.title =
              "updating " + this.postData.title + " | icandoathing";
            if (
              this.postData.author.username !==
              this.$store.state.userData.username
            ) {
              this.$router.push("/");
            }
            this.$store.commit("setLoading", false);
          })
          .catch(() => {
            if (
              this.postData.author.username !==
              this.$store.state.userData.username
            ) {
              this.$router.push("/");
            }
            this.$store.commit("setLoading", false);
          });
      }
    },
  },
  beforeMount() {
    this.checkForPostData();
  },
  beforeUnmount() {
    this.$store.commit("clearViewPost");
  },
  beforeRouteUpdate(to, from, next) {
    this.postData = undefined;
    this.checkForPostData();
    next();
  },
};
</script>

<style lang="scss" scoped>
.page-update-post {
  width: 100%;
}
</style>
