<template>
  <div>
    <h4 class="page-title">update everyone on the thing you did!</h4>
    <div class="top-area">
      <p class="text">You can change these or leave them the same:</p>
      <post-title-input
        ref="titleBox"
        :inputTitle="postData.title"
        :inputDescription="postData.description"
        :inputTags="postData.tags.map((x) => x.name).join(' ')"
        @titleChange="(ev) => (title = ev)"
        @descriptionChange="(ev) => (description = ev)"
        @tagsChange="(ev) => (tags = ev)"></post-title-input>
    </div>
    <div class="info-container">
      <h3 class="info-text">Your original post:</h3>
    </div>
    <div class="sections-container">
      <post-view-segment
        v-for="segment in postData.album.sections"
        :key="segment.id"
        :image="segment.thumbnail"
        :text="segment.content"
        :externalURL="segment.external_url"></post-view-segment>
    </div>
    <div class="info-container">
      <h3 class="info-text">Add more stuff:</h3>
    </div>
    <post-submit-section
      v-for="(section, i) in sections"
      :key="i"
      :ref="'section' + i"
      @newwords="(e) => updateWords(i, e)"
      @newimage="(e) => updateImage(i, e)"
      @newlink="(e) => updateLink(i, e)"></post-submit-section>
    <div
      class="add-new-section-area"
      @click="sections.push({ image: '', text: '' })">
      <h5 class="add-section-text">add a new section</h5>
    </div>
    <div class="extra-section">
      <div class="extra-questions">
        <p class="project-status-text text">
          What is the status of this project?
        </p>
        <select
          name="project-status"
          id=""
          v-model="projectStatus"
          class="project-status-select">
          <option value="p" class="text-purple">Planning</option>
          <option value="i" class="text-primary">In Progress</option>
          <option value="s" class="text-warning">Stalled</option>
          <option value="c" class="text-yellow">Completed</option>
        </select>
      </div>
      <div
        class="main-image"
        v-if="sections.filter((x) => x.image !== '').length">
        <p class="text main-image-text">Which image to use as the thumbnail?</p>
        <div class="image-container">
          <div
            v-for="(section, i) of postData.album.sections.concat(sections)"
            :key="i"
            class="small-image-container"
            :style="
              !section.image && !section.external_url ? 'display: none;' : ''
            ">
            <img
              v-if="section.image || section.external_url"
              :src="imageSources[i]"
              alt="hi"
              class="small-image"
              :class="[mainImage === i ? 'clicked' : '']"
              @click="mainImage = i" />
          </div>
        </div>
      </div>
    </div>
    <div class="buttons-container-row">
      <button
        class="button button-primary-filled"
        :class="[buttonDisabled ? 'disabled' : '']"
        @click="submitForm()">
        post update
      </button>
      <button
        class="button button-primary"
        @click="
          $notify({
            title: 'sorry',
            body: 'this isnt finished yet',
            type: 'warning',
          })
        ">
        preview post
      </button>
    </div>
  </div>
</template>

<script>
import PostSubmitSection from "./PostSubmitSection.vue";
import PostTitleInput from "./PostTitleInput.vue";
import axios from "axios";
import PostViewSegment from "./PostViewSegment.vue";
import { imageMixin } from "@/mixins/imageMixin.js";
export default {
  components: { PostSubmitSection, PostViewSegment, PostTitleInput },
  name: "PostUpdateForm",
  props: {
    postData: {
      type: Object,
      default: undefined,
    },
  },
  mixins: [imageMixin],
  mounted() {
    this.tags = this.postData.tags.map((x) => x.name).join(" "); //TODO
    this.title = this.postData.title;
    this.description = this.postData.description;
    this.projectStatus = this.postData.status;
    if (this.postData.album.sections.length > 0) {
      this.mainImage = this.postData.main_image;
    }
    this.postData.album.sections.forEach((section, i) => {
      if (section.image || section.external_url) {
        let url = section.image;
        if (url == null || url == "") {
          url = section.external_url;
        }
        this.getSrc(url).then((src) => {
          this.imageSources[i] = src;
        });
      }
    });
  },
  data() {
    return {
      sections: [{ image: "", text: "" }],
      title: "",
      description: "",
      tags: "",
      projectStatus: "i",
      completionDate: new Date().toISOString().split("T")[0],
      mainImage: 0,
      isPrivate: false,
      buttonDisabled: false,
      imageSources: [],
    };
  },
  methods: {
    updateWords(i, e) {
      this.sections[i].text = e;
    },
    updateImage(i, e) {
      this.sections[i].image = e;
      this.imageSources[i + this.postData.album.sections.length] =
        this.loadingImageSrc;
      this.getSrc(e).then((url) => {
        this.imageSources[i + this.postData.album.sections.length] = url;
      });
    },
    updateLink(i, e) {
      this.sections[i].image = "url:" + e;
      this.imageSources[i + this.postData.album.sections.length] =
        this.loadingImageSrc;
      this.getSrc(e).then((url) => {
        this.imageSources[i + this.postData.album.sections.length] = url;
        console.log(this.imageSources);
      });
    },
    submitForm() {
      console.log(this.sections);
      if (
        this.sections.length < 2 &&
        this.sections[0].text == "" &&
        this.sections[0].image == ""
      ) {
        this.$refs.section0[0].$refs.error.addError(
          "Add at least one new image or text to make an update"
        );
        return;
      }
      this.buttonDisabled = true;
      this.$store.commit("setLoading", true);

      let data = new FormData();
      data.set("post_id", this.postData.id);
      if (this.postData.title !== this.title) {
        data.set("title", this.title);
      }
      if (this.postData.description !== this.description) {
        data.set("description", this.description);
      }
      if (this.postData.tags.map((x) => x.name).join(" ") !== this.tags) {
        data.set(
          "tags",
          this.tags.split(" ").filter((x) => x !== " " && x !== "")
        );
      }
      data.set("owner", this.$store.state.userData.username);
      data.set("sections", this.sections.length);
      data.set("main_image", this.mainImage);
      if (this.postData.status !== this.projectStatus) {
        data.set("project_status", this.projectStatus);
      }
      if (this.projectStatus === "c") {
        data.set("completion_date", this.completionDate);
      }
      this.sections.forEach((section, i) => {
        console.log(section);
        if (section.image !== "") {
          data.set(
            "image_" + (i + this.postData.album.sections.length),
            section.image
          );
        }
        if (section.text !== "") {
          data.set(
            "content_" + (i + this.postData.album.sections.length),
            section.text
          );
        }
      });

      axios
        .post("/api/v1/posts/update/", data)
        .then((res) => {
          if (res.status == 201) {
            this.$router.push("/thing/" + res.data.id + "/" + res.data.slug);
          }
          console.log(res);
          this.$store.commit("setLoading", false);
        })
        .catch((err) => {
          console.log(err);
          if (!err.response || err.response.status == 500) {
            this.$notify({
              title: "sorry",
              body: "there seems to be an issue with the servers, please try again after a few minutes.",
              type: "danger",
              duration: 5000,
            });
            this.$store.commit("setLoading", false);
            this.buttonDisabled = false;
            return;
          }
          Object.keys(err.response.data).forEach((key) => {
            switch (key) {
              case "title":
                this.$refs.titleBox.$refs.titleError.addErrors(
                  err.response.data[key]
                );
                break;
              case "slug":
                break;
              case "tags":
                this.$refs.titleBox.$refs.tagsError.addErrors(
                  err.response.data[key]
                );
                break;
              case "description":
                this.$refs.titleBox.$refs.descriptionError.addErrors(
                  err.response.data[key]
                );
                break;
              case "content":
                this.$refs[
                  "section" + (this.sections.length - 1)
                ][0].$refs.error.addError(
                  "One of your sections has the following issue:"
                );
                this.$refs[
                  "section" + (this.sections.length - 1)
                ][0].$refs.error.addErrors(err.response.data[key]);
            }
          });
          this.buttonDisabled = false;
          this.$store.commit("setLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  text-transform: uppercase;
  color: $grey-600;
  font-weight: 400;
  font-size: 1.5rem;
  margin: $item-margin;
}
.top-area {
  display: flex;
  flex-direction: column;
  margin: $item-margin;
  border-radius: $border-radius;
}
.input {
  background-color: $white;
  border-radius: $border-radius;
  min-height: 2rem;
  width: 100%;
  font-size: 1rem;
  padding-left: $item-margin;
  color: $black;
  &:hover {
    &::placeholder {
      color: $primary-500;
    }
  }
  &::placeholder {
    font-weight: 300;
    transition: 0.2s;
  }
}
#tag-input {
  height: 4rem;
}
#description-input {
  height: 6rem;
}
.add-new-section-area {
  display: flex;
  height: $content-width-lg/5;
  margin: $item-margin;
  border: dashed $grey-300 3px;
  border-radius: $border-radius;
  justify-content: center;
  align-items: center;
  color: $grey-300;
  font-size: 1.5em;
  font-weight: 400;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    border-color: $primary-300;
    color: $primary-300;
  }
}

.extra-section {
  width: inherit;
  background-color: $grey-500;
  height: fit-content;
  display: flex;
  border-radius: $border-radius;
  margin: $item-margin;
  padding: 0.5rem;
  justify-content: space-between;
}
.image-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.2rem;
  column-gap: 0.2rem;
  padding: $item-margin;
}
.small-image-container {
  width: 4rem;
}
.text {
  font-size: 0.8rem;
  color: $black;
}
.small-image {
  border-radius: $border-radius;
  cursor: pointer;
  &:hover {
    outline: $info-500 solid 2px;
  }
}
.clicked {
  outline: $yellow solid 2px !important;
}
.sections-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.sections-container > * {
  max-width: 48%;
  height: 12rem !important;
}
.info-container {
  background-color: $grey-200;
  margin: $item-margin;
  border-radius: $border-radius;
  padding: $item-margin;
  padding-left: 2rem;
}
.info-text {
  color: $primary-600;
  font-size: 1.2rem;
  font-weight: 300;
  margin: 0;
  text-transform: uppercase;
}

@media screen and (max-width: $switch-width) {
  .main-section {
    flex-direction: column;
    row-gap: 0.5rem;
    padding: 0.5rem;
  }
  .main-section-left {
    row-gap: 0.5rem;
  }
  .sections-container > * {
    max-width: 45%;
    height: 12rem !important;
  }
}
</style>
